import Component from 'client/core/Component';
import { getJson } from 'client/utils/ajax';
import { arvatoTrackSave } from 'urls';

const SESSION_KEY_FOUND = 'arvato.track.request';

function isSaveRequestSend() {
    const isFound = sessionStorage.getItem(SESSION_KEY_FOUND);

    return !!isFound;
}
export default class ArvatoCookieTracker extends Component {
    init () {
        this.emitter.addListener('resetArvatoTrack', this.resetArvatoTrack.bind(this));

        if (!isSaveRequestSend()) {
            var observerConfig = { childList: true, subtree: true };
            var observer = new MutationObserver(this.observerCallback);

            observer.observe(document.body, observerConfig);
        }
    }

    resetArvatoTrack() {
        sessionStorage.removeItem(SESSION_KEY_FOUND);
    }

    observerCallback(mutations) {
        mutations.forEach(function (mutation) {
            [].filter.call(mutation.addedNodes, function (node) {
                return node.nodeName === 'IFRAME';
            }).forEach(function (node) {
                node.addEventListener('load', function () {
                    if (node.name === '_itt_iframe' && !isSaveRequestSend()) {
                        var url = arvatoTrackSave;

                        getJson({
                            type: 'post',
                            url: url,
                            data: {}
                        }).then(response => {
                            if (response && response.success) {
                                sessionStorage.setItem(SESSION_KEY_FOUND, '_itt_iframe_found');
                            }
                        });
                    }
                });
            });
        });
    }

}
